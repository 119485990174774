import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Button,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import { translationHook } from "../../../translationHook";
import {
	ButtonInfo,
	ButtonPrimary,
	ButtonSecondary,
	ButtonWarning,
	ButtonDanger,
	ButtonPurple,
} from "../../../UI/Buttons";
import { Select, TextField, Checkboxes } from "mui-rff";

import {
	setLoading,
	triggerNotification,
	triggerDialogForm,
} from "../../../../actions";

class InvestorsInterviewsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		suggestedDayList: [],
		interviewsList: [],
		timesList: [],
	};

	getInterviewDaysList = async () => {
		let arr = [],
			at = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Interview_Days_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				arr.push({
					value: item,
					label: item,
				});
			});

			this.setState({ suggestedDayList: arr });
		}
	};

	saveInterviewSuggestions = async ({ data }) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Interview_Times_Suggestions",
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					Interview_Id: data?.Interview_Id,
					First_Interview_Time: data?.First_Interview_Time,
					Second_Interview_Time: data?.Second_Interview_Time,
					Third_Interview_Time: data?.Third_Interview_Time,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					this.setState({
						interviewsList:
							result?.data?.Data?.Interview_Risk_Rating
								?.Investors_Interviews,
					});
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	resendInterviewSuggestions = async ({ data }) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Resend_Interview_Times_Suggestions",
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					Interview_Id: data?.Interview_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	resendInterviewLink = async ({ data }) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Resend_Interview_Meeting_Link",
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					Interview_Id: data?.Interview_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	setInterviewDone = async ({ data }) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Set_Investor_Interview_Done",
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					Interview_Id: data?.Interview_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				if (result.data.Status) {
					this.setState({
						interviewsList:
							result?.data?.Data?.Interview_Risk_Rating
								?.Investors_Interviews,
					});
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	handleSuggestedDayChange = async (v, interviewId, key) => {
		let arr = [],
			at = this.props.currentUser.Data.access_token,
			recordIndex = this.state.interviewsList.findIndex(
				(r) => r?.Interview_Id === interviewId
			);

		if (recordIndex !== -1) {
			let record = this.state.interviewsList[recordIndex];

			record[key] = v;

			let modifiedInterviewsList = this.state.interviewsList;

			modifiedInterviewsList[recordIndex] = record;

			this.setState({ interviewsList: modifiedInterviewsList });
		}

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Interview_Day_Times_List",
			{ Interview_Id: interviewId, Selected_Day: v },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let modifiedList = [];

			response.data.Data.map((item) => {
				arr.push({
					value: item,
					label: item,
				});
			});

			if (this.state.timesList.length > 0) {
				let timeIndex = this.state.timesList.findIndex(
					(t) => t?.Interview_Id === interviewId && t?.key === key
				);

				modifiedList = this.state.timesList;

				if (timeIndex !== -1) {
					let time = this.state.timesList[timeIndex];

					time["timesList"] = arr;

					modifiedList[timeIndex] = time;
				} else {
					modifiedList.push({
						Interview_Id: interviewId,
						key: key,
						timesList: arr,
						value: v,
					});
				}

				this.setState({ timesList: modifiedList });
			} else {
				this.setState({
					timesList: [
						{
							Interview_Id: interviewId,
							key: key,
							timesList: arr,
							value: v,
						},
					],
				});
			}
		}
	};

	handleSuggestedTimeChange = (v, timeObj) => {
		let recordIndex = this.state.interviewsList.findIndex(
			(r) => r?.Interview_Id === timeObj?.Interview_Id
		);

		if (recordIndex !== -1) {
			let record = this.state.interviewsList[recordIndex];

			record[timeObj?.key] = timeObj?.value + " " + v;

			let modifiedList = this.state.interviewsList;

			modifiedList[recordIndex] = record;

			this.setState({ interviewsList: modifiedList });
		}
	};

	unlockControl = ({ data }) => {
		let recordIndex = this.state.interviewsList.findIndex(
			(r) => r?.Interview_Id === data?.Interview_Id
		);

		if (recordIndex !== -1) {
			let record = this.state.interviewsList[recordIndex];

			record["Lock_Controls"] = 0;
			record["Btn_Set_Time_Suggestion"] = 1;

			let modifiedList = this.state.interviewsList;

			modifiedList[recordIndex] = record;

			this.setState({ interviewsList: modifiedList });
		}
	};

	renderInterviewTimeResult = ({ data }, key) => {
		let timesList = this.state.timesList.find((t) => {
			return t["Interview_Id"] === data?.Interview_Id && t["key"] === key;
		});

		let day = data[key] ? data[key].split(" ")[0] : null,
			time = data[key] ? data[key].split(" ")[1] : null;

		return (
			<div className="d-flex flex-column" style={{ minWidth: "150px" }}>
				{data?.Lock_Controls || this.props.lockControl ? (
					<>
						{day && (
							<div className="d-flex flex-column">
								<label
									style={{
										color: "#00b1eb",
										fontSize: "0.8rem",
									}}
								>
									{this.props.t("suggestedDay")}
								</label>
								<span>{day}</span>
							</div>
						)}

						{time && (
							<div className="d-flex flex-column mt-2">
								<label
									style={{
										color: "#00b1eb",
										fontSize: "0.8rem",
									}}
								>
									{this.props.t("suggestedTime")}
								</label>
								<span>{time}</span>
							</div>
						)}
					</>
				) : (
					<>
						<Select
							disabled={
								this.props.lockControl || data?.Lock_Controls
							}
							label={this.props.t("suggestedDay")}
							name={`suggestedDay_${key}_${data?.Interview_Id}`}
							data={this.state.suggestedDayList}
							value={day}
							onChange={(e) => {
								this.handleSuggestedDayChange(
									e.target.value,
									data?.Interview_Id,
									key
								);
							}}
						></Select>

						<Select
							disabled={
								this.props.lockControl || data?.Lock_Controls
							}
							label={this.props.t("suggestedTime")}
							name={`suggestedTime_${key}_${data?.Interview_Id}`}
							data={timesList?.timesList ?? []}
							value={time}
							onChange={(e) => {
								this.handleSuggestedTimeChange(
									e.target.value,
									timesList
								);
							}}
						></Select>
					</>
				)}
			</div>
		);
	};

	componentDidMount() {
		this.getInterviewDaysList();

		this.setState({ interviewsList: this.props.interviewsList ?? [] });
	}

	render() {
		let columnAlign = "left",
			jsonDataSource = new CustomStore({
				key: "Interview_Id",
				load: () => {
					return this.state.interviewsList;
				},
			});

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id={`dataGrid_${this.props.key}`}
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={false}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<SearchPanel
						visible={false}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={false} />

					<HeaderFilter visible={false} />

					<Export enabled={false} allowExportSelectedData={false} />

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Investor_Name_AR"
								: "Investor_Name_EN"
						}
						width="auto"
						caption={this.props.t("investorName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Passport_Number"
						width="auto"
						caption={this.props.t("Passport_Number")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Investor_Mail"
						width="auto"
						caption={this.props.t("email")}
						alignment={columnAlign}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("First_Interview_Time")}
						alignment={columnAlign}
						cellRender={(row) => {
							return this.renderInterviewTimeResult(
								row,
								"First_Interview_Time"
							);
						}}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("Second_Interview_Time")}
						alignment={columnAlign}
						cellRender={(row) => {
							return this.renderInterviewTimeResult(
								row,
								"Second_Interview_Time"
							);
						}}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("Third_Interview_Time")}
						alignment={columnAlign}
						cellRender={(row) => {
							return this.renderInterviewTimeResult(
								row,
								"Third_Interview_Time"
							);
						}}
					></Column>

					<Column
						dataField="Selected_Interview_Time"
						width="auto"
						caption={this.props.t("Selected_Interview_Time")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Interview_Status_Name_AR"
								: "Interview_Status_Name_EN"
						}
						width="auto"
						caption={this.props.t("interviewStatus")}
						alignment={columnAlign}
					></Column>

					{!this.props.lockControl && (
						<Column
							cssClass="outlinedColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
							width="200px"
						>
							<Button
								render={(record) =>
									!!record.data.Btn_Set_Time_Suggestion ? (
										<ButtonPrimary
											className="my-2"
											onClick={(e) => {
												this.saveInterviewSuggestions(
													record
												);
											}}
										>
											{this.props.t("sendSuggestion")}
										</ButtonPrimary>
									) : null
								}
							/>

							<Button
								render={(record) =>
									!!record.data.Btn_Resend_Time_Suggestion ? (
										<ButtonPurple
											className="my-2"
											onClick={(e) => {
												this.resendInterviewSuggestions(
													record
												);
											}}
										>
											{this.props.t("reSendSuggestion")}
										</ButtonPurple>
									) : null
								}
							/>

							<Button
								render={(record) =>
									!!record.data
										.Btn_Resend_Interview_Meeting ? (
										<ButtonSecondary
											className="my-2"
											onClick={(e) => {
												this.resendInterviewLink(
													record
												);
											}}
										>
											{this.props.t(
												"reSendInterviewMeeting"
											)}
										</ButtonSecondary>
									) : null
								}
							/>

							<Button
								render={(record) =>
									!!record.data.Btn_Reset_Time_Suggestion ? (
										<ButtonWarning
											className="my-2"
											onClick={(e) => {
												this.unlockControl(record);
											}}
										>
											{this.props.t("resetSuggestion")}
										</ButtonWarning>
									) : null
								}
							/>

							<Button
								render={(record) =>
									!!record.data.Btn_Interview_Done ? (
										<ButtonDanger
											className="my-2"
											onClick={(e) => {
												this.setInterviewDone(record);
											}}
										>
											{this.props.t("interviewDone")}
										</ButtonDanger>
									) : null
								}
							/>
						</Column>
					)}
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("riskRating");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(InvestorsInterviewsList));
