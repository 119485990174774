import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import DateBox from "devextreme-react/date-box";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import moment from "moment";
import { Link, navigate } from "@reach/router";
import { ButtonSecondary, ButtonPrimary, ButtonDanger } from "../../UI/Buttons";
import apiConfig from "../../../apis/apiConfig";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { getCompanyDetails } from "../../../apis/licenses/LicensesList";

class CompanyForm extends Component {
	state = {
		initValues: {},
		Company_Data_Validation: [],
		Company_Details: {},
		Company_System_Validation: {},
		Btns: [],
		companyStatusList: [],
		ownershipStructureList: [],
		legalFormsList: [],
		licenseOfferList: [],
		licenseYearsList: [],
		clientsList: [],
		selectedLegalForm: null,
		selectedOfferId: null,
		Electronic_Signature_File: [],
		Electronic_Signature_File_Code: null,
		dataLoaded: false,
	};

	selectFiles = (event, field) => {
		let filesData = [];

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				filesData.push(element);
			}
		}

		this.setState({
			Electronic_Signature_File: filesData,
			Electronic_Signature_File_Code: null,
		});
	};

	getCompanyStatusList = async () => {
		let statusArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_COMPANIES_STATUS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				statusArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ companyStatusList: statusArr });
		}
	};

	getOwnershipStructureList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_OWNERSHIP_STRUCTURES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ ownershipStructureList: arr });
		}
	};

	getLegalFormsList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LEGALS_FORMS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Legal_Form_Name_AR
						: item.License_Legal_Form_Name_EN;

				arr.push({
					value: item.License_Legal_Form_Id,
					label,
				});
			});

			this.setState({ legalFormsList: arr });
		}
	};

	getLicenseOfferList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_OFFERS",
			{ License_Legal_Form_Id: this.state.selectedLegalForm }
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
					Visas_Count: item.Visas_Count,
				});
			});

			this.setState({ licenseOfferList: arr });
		}
	};

	getLicenseYearsList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_OFFERS_REGISTRATION",
			{ License_Offer_Id: this.state.selectedOfferId }
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseYearsList: arr });
		}
	};

	getClientsList = async () => {
		let arr = [],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/COMPANIES/Get_Companies_Main_Clients",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Mail_Client_Profile_Name_AR
						: item.Mail_Client_Profile_Name_EN;

				arr.push({
					value: item.Mail_Client_Profile_Id,
					label,
				});
			});

			this.setState({ clientsList: arr });
		}
	};

	getFacilityTypesList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_FACILITIES_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ facilityTypes: arr });
		}
	};

	deleteCompanyDetails = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/Del_Company";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Company_Id: this.props.companyId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				// if (!err?.data?.Data?.Validation_Result) {
				// 	this.setState({
				// 		Company_Data_Validation:
				// 			err?.data?.Data?.Validation_Results_List,
				// 	});
				// } else {
				// 	this.props.triggerNotification(
				// 		true,
				// 		err.response ? err.response.data : err.data
				// 	);
				// }

				return err;
			});
	};

	deleteCompanySignatureFile = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/Del_Company_Signature_File";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Company_Id: this.props.companyId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
					this.setState({
						Electronic_Signature_File: [],
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	issueLegalFiles = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/Print_Company_Legal_Files";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Company_Id: this.props.companyId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						Company_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	updateCompanyDetails = async (values, form, print) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/Upd_Company";

		let bodyData = {
			...values,
			...{
				Print_Legal_Files: 0,
				Family_Owned_Company_Id: values?.Family_Owned_Company_Id
					? 1
					: 0,
			},
		};

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					this.setState({
						Company_Data_Validation: !res.data?.Data
							?.Company_Data_Validation?.Validation_Result
							? res.data?.Data?.Company_Data_Validation
									?.Validation_Results_List
							: res.data?.Data?.Company_Data_Validation,
						Company_Details: res.data?.Data?.Company_Details,
						Company_System_Validation:
							res.data?.Data?.Company_System_Validation,
						Btns: {
							Btn_Delete: res.data?.Data?.Btn_Delete,
							Btn_Print: res.data?.Data?.Btn_Print,
							Btn_Save: res.data?.Data?.Btn_Save,
							Btn_Save_Print: res.data?.Data?.Btn_Save_Print,
						},
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						Company_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});

		//if file
		if (
			this.state.Electronic_Signature_File &&
			this.state.Electronic_Signature_File.length > 0 &&
			!this.state.Electronic_Signature_File_Code
		) {
			const bodyFormData = new FormData();

			bodyFormData.append(
				"PARAMETERS",
				JSON.stringify({ Company_Id: this.props.companyId })
			);
			bodyFormData.append(
				"Electronic_Signature_File",
				this.state.Electronic_Signature_File[0]
			);

			const uploadFile = await apiConfig
				.post(
					"/API/ADMINISTRATION/LICENSES/COMPANIES/Upd_Company_Signature_File",
					bodyFormData,
					{
						headers: {
							Authorization: `Bearer ${at}`,
						},
					}
				)
				.then((res) => {
					if (!res.data.Status) {
						throw res;
					}

					this.setState({ File_Code: res?.data?.Data?.File_Code });

					return res;
				})
				.catch((err) => {
					if (!err?.data?.Data?.Validation_Result) {
						this.setState({
							Company_Data_Validation:
								err?.data?.Data?.Validation_Results_List,
						});
					} else {
						this.props.triggerNotification(
							true,
							err.response ? err.response.data : err.data
						);
					}

					return err;
				});
		}

		if (print) {
			this.issueLegalFiles();
		}
	};

	addCompany = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/Add_Company";

		const bodyFormData = new FormData();

		let bodyData = {
			...values,
			...{
				Family_Owned_Company_Id: values?.Family_Owned_Company_Id
					? 1
					: 0,
			},
		};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		//if file
		if (
			this.state.Electronic_Signature_File &&
			this.state.Electronic_Signature_File.length > 0
		) {
			bodyFormData.append(
				"Electronic_Signature_File",
				this.state.Electronic_Signature_File[0]
			);
		}

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);

					navigate(
						`/manage-companies/company-info/${res.data.Data.Company_Details.Company_Id}`
					);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						Company_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	componentDidMount() {
		let companyId = this.props.companyId,
			auth = this.props.currentUser.Data.access_token;

		Promise.all([
			this.getCompanyStatusList(),
			this.getOwnershipStructureList(),
			this.getLegalFormsList(),
			this.getClientsList(),
			this.getFacilityTypesList(),
		]).then(() => {
			this.setState({ dataLoaded: true });

			if (companyId) {
				(async () => {
					const companyDetails = await getCompanyDetails(
						auth,
						companyId
					);

					//check if can edit add delete button on file
					if (
						companyDetails?.Btn_Save &&
						companyDetails?.Company_Details
							?.Electronic_Signature_File_Code
					) {
						companyDetails.Company_Details.Electronic_Signature_File[
							"Can_Delete"
						] = 1;
					}

					this.setState({
						Company_Data_Validation: !companyDetails
							?.Company_Data_Validation?.Validation_Result
							? companyDetails?.Company_Data_Validation
									?.Validation_Results_List
							: companyDetails?.Company_Data_Validation,
						Company_Details: companyDetails?.Company_Details,
						Company_System_Validation:
							companyDetails?.Company_System_Validation,
						Electronic_Signature_File: companyDetails
							?.Company_Details?.Electronic_Signature_File_Code
							? [
									companyDetails?.Company_Details
										?.Electronic_Signature_File,
							  ]
							: [],
						Electronic_Signature_File_Code:
							companyDetails?.Company_Details
								?.Electronic_Signature_File_Code,
						Btns: {
							Btn_Delete: companyDetails?.Btn_Delete,
							Btn_Print: companyDetails?.Btn_Print,
							Btn_Save: companyDetails?.Btn_Save,
							Btn_Save_Print: companyDetails?.Btn_Save_Print,
						},
						selectedLegalForm:
							companyDetails?.Company_Details
								?.License_Legal_Form_Id,
						selectedOfferId:
							companyDetails?.Company_Details?.License_Offer_Id,
					});
				})();
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedLegalForm !== this.state.selectedLegalForm) {
			this.getLicenseOfferList();
		}

		if (prevState.selectedOfferId !== this.state.selectedOfferId) {
			this.getLicenseYearsList();
		}
	}

	onSubmit = async (values) => {};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		let { Company_Details, Company_System_Validation } = this.state;

		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					<Form
						onSubmit={this.onSubmit}
						initialValues={Company_Details}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
							handleReset,
						}) => (
							<form
								onSubmit={handleSubmit}
								onReset={handleReset}
								style={{ overflowX: "hidden" }}
								className="py-4 px-3"
								noValidate
							>
								<Row>
									{Company_Details && (
										<Col md={12}>
											<div className="d-flex justify-content-between align-items-center mb-4">
												<h3>
													{this.props.i18n
														.language === "ar"
														? Company_Details?.Company_Name_AR
														: Company_Details?.Company_Name_EN}

													<smal
														className="text-body mx-2"
														style={{
															fontSize: "1rem",
														}}
													>
														{
															Company_Details?.Company_No
														}
													</smal>
												</h3>

												{this.props.isAddForm && (
													<ButtonSecondary
														variant="outlined"
														endIcon={
															<ExitToAppIcon />
														}
														component={Link}
														to="/manage-companies"
														size="large"
													>
														{this.props.t(
															"exitToCompanieslist"
														)}
													</ButtonSecondary>
												)}
											</div>

											<Row>
												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Company_Id"
														)}
														className="read-only mb-3"
														name="Company_Id"
													/>
												</Col>
												<Col md={3}>
													<TextField
														label={this.props.t(
															"Company_No"
														)}
														className="read-only mb-3"
														name="Company_No"
													/>
												</Col>

												<Col md={3}>
													<Field name="Company_Status_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"companyStatus"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.companyStatusList
																}
																name="Company_Status_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3} className="pt-3">
													<Switches
														color="primary"
														name="Family_Owned_Company_Id"
														data={{
															label: `${this.props.t(
																"familyOwnedCompany"
															)}`,
															value: `${this.props.t(
																"familyOwnedCompany"
															)}`,
														}}
													/>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<TextField
														label={this.props.t(
															"Company_Name_AR"
														)}
														className="read-only mb-3"
														name="Company_Name_AR"
													/>
												</Col>
												<Col md={6}>
													<TextField
														label={this.props.t(
															"Company_Name_EN"
														)}
														className="read-only mb-3"
														name="Company_Name_EN"
													/>
												</Col>
											</Row>

											<Row>
												<Col md={3}>
													<Field name="License_Ownership_Structure_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"ownerShipStructure"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.ownershipStructureList
																}
																name="License_Ownership_Structure_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<label
														style={{
															fontSize: "0.8rem",
															color: "#00b1eb",
															textAlign: "right",
															display: "block",
															right: "0.5rem",
															position:
																"relative",
															marginBottom: "5px",
															marginTop: "-4px",
														}}
													>
														{this.props.t(
															"incorporationDate"
														)}
													</label>
													<Field
														name="Company_Incorporation_Date"
														subscription={{
															error: true,
															initial: true,
														}}
													>
														{(fieldProps) => (
															<DateBox
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"incorporationDate"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Company_Incorporation_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Company_Incorporation_Date
																}
																isValid={() => {
																	return (
																		!fieldProps
																			.meta
																			?.invalid &&
																		!fieldProps
																			.meta
																			?.submitFailed
																	);
																}}
															/>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<label
														style={{
															fontSize: "0.8rem",
															color: "#00b1eb",
															textAlign: "right",
															display: "block",
															right: "0.5rem",
															position:
																"relative",
															marginBottom: "5px",
															marginTop: "-4px",
														}}
													>
														{this.props.t(
															"issueDate"
														)}
													</label>
													<Field
														name="Company_Issue_Date"
														subscription={{
															error: true,
															initial: true,
														}}
													>
														{(fieldProps) => (
															<DateBox
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"issueDate"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Company_Issue_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Company_Issue_Date
																}
																isValid={() => {
																	return (
																		!fieldProps
																			.meta
																			?.invalid &&
																		!fieldProps
																			.meta
																			?.submitFailed
																	);
																}}
															/>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<label
														style={{
															fontSize: "0.8rem",
															color: "#00b1eb",
															textAlign: "right",
															display: "block",
															right: "0.5rem",
															position:
																"relative",
															marginBottom: "5px",
															marginTop: "-4px",
														}}
													>
														{this.props.t(
															"expireDate"
														)}
													</label>
													<Field
														name="Company_Expire_Date"
														subscription={{
															error: true,
															initial: true,
														}}
													>
														{(fieldProps) => (
															<DateBox
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"expireDate"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Company_Expire_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Company_Expire_Date
																}
																isValid={() => {
																	return (
																		!fieldProps
																			.meta
																			?.invalid &&
																		!fieldProps
																			.meta
																			?.submitFailed
																	);
																}}
															/>
														)}
													</Field>
												</Col>
											</Row>

											<Row>
												<Col md={3}>
													<Field name="License_Legal_Form_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"legalForm"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.legalFormsList
																}
																name="License_Legal_Form_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);

																	this.setState(
																		{
																			selectedLegalForm:
																				e
																					.target
																					.value,
																		}
																	);
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<Field name="License_Offer_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"licenseOffer"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.licenseOfferList
																}
																name="License_Offer_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																	this.setState(
																		{
																			selectedOfferId:
																				e
																					.target
																					.value,
																		}
																	);

																	let offerObj =
																		this.state.licenseOfferList.find(
																			(
																				i
																			) =>
																				i?.value ===
																				e
																					.target
																					.value
																		);

																	if (
																		offerObj
																	) {
																		form.change(
																			"Visas_Count",
																			offerObj?.Visas_Count
																		);
																	}
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<Field name="License_Offer_Registration_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"licenseyears"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.licenseYearsList
																}
																name="License_Offer_Registration_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																	// this.setState({
																	// 	selectedOfferId:
																	// 		e.target
																	// 			.value,
																	// });
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<Field name="Main_Client_Profile_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"registrationClient"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.clientsList
																}
																name="Main_Client_Profile_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																	// this.setState({
																	// 	selectedOfferId:
																	// 		e.target
																	// 			.value,
																	// });
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"Investors_Count"
														)}
														className="read-only mb-3"
														name="Investors_Count"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"Company_Capital"
														)}
														className="read-only mb-3"
														name="Company_Capital"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"Company_Shares_Count"
														)}
														className="read-only mb-3"
														name="Company_Shares_Count"
													/>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Company_Share_Value"
														)}
														className="read-only mb-3"
														name="Company_Share_Value"
														value={
															parseInt(
																values.Company_Capital
															) /
															parseInt(
																values.Company_Shares_Count
															)
														}
													/>
												</Col>

												<Col md={3}>
													<Field name="Facility_Type_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"facilityType"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.facilityTypes
																}
																name="Facility_Type_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"packageVisasCount"
														)}
														className="read-only mb-3"
														name="Visas_Count"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"extraVisasCount"
														)}
														className="read-only mb-3"
														name="Extra_Visas_Count"
														onChange={(e) => {
															form.batch(() => {
																form.change(
																	"Extra_Visas_Count",
																	e.target
																		.value
																);
																form.change(
																	"Total_Visas_Count",
																	values.Visas_Count +
																		values.Extra_Visas_Count
																);
															});
														}}
														onBlur={(e) => {
															form.batch(() => {
																form.change(
																	"Extra_Visas_Count",
																	e.target
																		.value
																);
																form.change(
																	"Total_Visas_Count",
																	values.Visas_Count +
																		values.Extra_Visas_Count
																);
															});
														}}
													/>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"totalVisasCount"
														)}
														className="read-only mb-3"
														name="Total_Visas_Count"
														value={
															parseInt(
																values.Visas_Count
															) +
															parseInt(
																values.Extra_Visas_Count
															)
														}
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"officeNumber"
														)}
														className="read-only mb-3"
														name="Company_Office_No"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"companyWebsite"
														)}
														className="read-only mb-3"
														name="Company_Website"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"companyEmail"
														)}
														className="read-only mb-3"
														name="Company_eMail"
													/>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"companyPhone"
														)}
														className="read-only mb-3"
														name="Company_Phone"
													/>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													{this.state
														.Electronic_Signature_File &&
													this.state
														.Electronic_Signature_File
														.length > 0 ? (
														<div
															style={{
																flexGrow: 1,
																marginInline:
																	"6px",
															}}
														>
															<label
																style={{
																	fontSize:
																		"1rem",
																	color: "#00b1eb",
																	textAlign:
																		"right",
																	display:
																		"block",
																	right: "0.5rem",
																	position:
																		"relative",
																	marginBottom:
																		"5px",
																	marginTop:
																		"-4px",
																}}
															>
																{this.props.t(
																	"electronicSignatureFile"
																)}
															</label>
															<FilesUpload
																view={true}
																attachments={[
																	{
																		Files_Data:
																			[
																				this
																					.state
																					.Electronic_Signature_File[0],
																			],
																	},
																]}
																deleteCB={() => {
																	if (
																		this
																			.props
																			.companyId
																	) {
																		this.deleteCompanySignatureFile();
																	} else {
																		this.setState(
																			{
																				Electronic_Signature_File:
																					[],
																				Electronic_Signature_File_Code:
																					null,
																			}
																		);
																	}
																}}
																downloadCB={
																	downloadAttachment
																}
															/>
														</div>
													) : (
														<ButtonSecondary
															color="primary"
															variant="contained"
															endIcon={
																<CloudUploadIcon />
															}
															style={{
																justifyContent:
																	"space-between",
																minWidth:
																	"200px",
															}}
														>
															{this.props.t(
																"electronicSignatureFile"
															)}
															<input
																style={{
																	position:
																		"absolute",
																	width: "100%",
																	height: "100%",
																	left: 0,
																	right: 0,
																	top: 0,
																	bottom: 0,
																	zIndex: 1,
																	opacity: 0,
																	fontSize: 0,
																	cursor: "pointer",
																}}
																type="file"
																onChange={(
																	e
																) => {
																	this.selectFiles(
																		e,
																		"Electronic_Signature_File"
																	);
																}}
																value=""
															/>
														</ButtonSecondary>
													)}
												</Col>

												<Col xs={12} className="mt-4">
													<TextField
														label={this.props.t(
															"companyNotes"
														)}
														className="read-only mb-3 mt-3"
														name="Company_Notes"
														rows={8}
														multiline
													/>
												</Col>
											</Row>

											<Row>
												{!Company_System_Validation?.Is_Valid &&
													Company_System_Validation
														?.Validation_Errors_List
														?.length > 0 && (
														<>
															<Col xs={12}>
																<label
																	style={{
																		fontSize:
																			"1.2rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"companyDataErrors"
																	)}
																</label>
															</Col>
															<Col
																xs={12}
																style={{
																	border: "4px solid red",
																	borderRadius:
																		"4px",
																	paddingBlock:
																		"18px",
																}}
															>
																<Row>
																	{Company_System_Validation?.Validation_Errors_List?.map(
																		(
																			error
																		) => {
																			return (
																				<Col
																					md={
																						3
																					}
																				>
																					<h5>
																						{this
																							.props
																							.i18n
																							.language ===
																						"ar"
																							? error.Error_Source_AR
																							: error.Error_Source_EN}
																					</h5>

																					<p>
																						{this
																							.props
																							.i18n
																							.language ===
																						"ar"
																							? error.Error_Message_AR
																							: error.Error_Message_EN}
																					</p>
																				</Col>
																			);
																		}
																	)}
																</Row>
															</Col>
														</>
													)}
											</Row>
										</Col>
									)}
								</Row>

								<div className="d-flex justify-content-center align-itmes-center my-5 mt-4">
									{this.props.isAddForm && (
										<ButtonPrimary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.addCompany(values, form);
											}}
										>
											{this.props.t("addCompany")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save && (
										<ButtonPrimary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateCompanyDetails(
													values,
													form,
													false
												);
											}}
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateCompanyDetails(
													values,
													form,
													true
												);
											}}
										>
											{this.props.t("saveAndIssue")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.issueLegalFiles();
											}}
										>
											{this.props.t("issueLegalFiles")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Delete && (
										<ButtonDanger
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.deleteCompanyDetails();
											}}
										>
											{this.props.t("deleteCompanyData")}
										</ButtonDanger>
									)}
								</div>

								{this.state.Company_Data_Validation.length >
									0 && (
									<Row>
										<Col
											xs={12}
											style={{
												border: "4px solid red",
												borderRadius: "4px",
												paddingBlock: "18px",
												color: "red",
											}}
										>
											<Row>
												{this.state.Company_Data_Validation?.map(
													(error) => {
														if (!error.Result) {
															return (
																<Col md={3}>
																	<h5>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Title_Name_AR
																			: error.Title_Name_EN}
																	</h5>

																	<p>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Error_AR
																			: error.Error_EN}
																	</p>
																</Col>
															);
														}
													}
												)}
											</Row>
										</Col>
									</Row>
								)}
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(CompanyForm));
