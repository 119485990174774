import apiConfig from '../apiConfig';

export const addDepartmentManagerJob = async (at, departmentJobId, empDepartmentJobId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_MANAGER_JOB', 
		{
			Department_Job_Id: departmentJobId,
            Emp_Department_Job_Id: empDepartmentJobId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
