import apiConfig from '../apiConfig';

export const deleteDepartmentManagerJob = async (at, departmentJobId, departmentJobManagerId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_MANAGER_JOB', 
		{
			Department_Job_Id: departmentJobId,
			Department_Job_Manager_Id: departmentJobManagerId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
