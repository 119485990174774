import apiConfig from '../apiConfig';

export const deleteDepartmentJobPermission = async (at, departmentJobId, departmentJobPermissionId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_JOB_PERMISSION', 
		{
			Department_Job_Id: departmentJobId,
			Department_Job_Permission_Id: departmentJobPermissionId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
