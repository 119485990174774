import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Selection
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Switch from '@material-ui/core/Switch';

import { getDepartmentManagerJobsList } from '../../../apis/departments/getDepartmentManagerJobsList'
import { addDepartmentManagerJob } from '../../../apis/departments/addDepartmentManagerJob'
import { deleteDepartmentManagerJob } from '../../../apis/departments/deleteDepartmentManagerJob'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'


class DepartmentWorkingGroupsUnderMyManagementList extends Component {


    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Selected}
			name={`jobStatus_${data.Department_Job_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					status = data.Selected,
                    departmentJobId = this.props.departmentJobId,
                    departmentJobManagerId = data.Department_Job_Manager_Id,
                    empDepartmentJobId = data.Emp_Department_Job_Id,
                    response

                    this.props.setLoading(true, 'dark')

                    if (status) {
                        response = await deleteDepartmentManagerJob(at, departmentJobId, departmentJobManagerId )
                    } else {
                        response = await addDepartmentManagerJob(at, departmentJobId, empDepartmentJobId )
                    }
                    
					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }


    render() {

        let columnAlign = 'left',
        JobNameKey = 'Emp_Department_Job_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            JobNameKey = 'Emp_Department_Job_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Emp_Department_Job_Id',
            load: () => {
                return getDepartmentManagerJobsList(at, this.props.departmentJobId)
            },
            onLoaded: (res) => {
                if (res?.length == 0) {
                    this.props.showTab(false)
                } else {
                    this.props.showTab(true)
                }
            }
        });
        
        return(
            <>

            <div className="container-fluid pb-3">
                
                <DataGrid
                    ref={this.dataGridRef}
                    className={this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en'}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
                    columnWidth={'auto'}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <ColumnChooser enabled={true}  allowSearch={true}/>

                    <SearchPanel 
                        visible={true}
                        width={240}
                        placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />
                    
                    <Column
                        width="100px"
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                    />


                    <Column dataField={JobNameKey} caption={this.props.t('jobName')} alignment={columnAlign}>
                    </Column>

                    
                </DataGrid>

            </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentWorkingGroupsUnderMyManagementList))