import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'
import { getDepartmentManagerEmployeesList } from '../../../apis/departments/getDepartmentManagerEmployeesList'

class DepartmentNamesOfEmployeesUnderMyManagementList extends Component {

    render() {

        let columnAlign = 'center',
        UserNameKey = 'User_Name_EN',
        DepartmentJobName = 'Department_Job_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            UserNameKey = 'User_Name_AR'
            DepartmentJobName = 'Department_Job_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'User_Id',
            load: () => {
                return getDepartmentManagerEmployeesList(at, this.props.departmentJobId)
            },
            onLoaded: (res) => {
                if (res?.length == 0) {
                    this.props.showTab(false)
                } else {
                    this.props.showTab(true)
                }
            }
        });

        return(
            <>
                <div className="container-fluid pb-3">
                    <DataGrid
                        ref={this.dataGridRef}
                        className={this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en'}
                        id="dataGrid"
                        width={'100%'}
                        dataSource={jsonDataSource}
                        showBorders={false}
                        showColumnLines= {false}
                        showRowLines= {true}
                        allowColumnResizing={true}
                        columnResizingMode={'widget'}
                        allowColumnReordering={true}
                        rowAlternationEnabled={false}
                        focusedRowEnabled={true}
                        columnWidth={'auto'}
                    >
                        <ColumnChooser enabled={true}  allowSearch={true}/>

                        <SearchPanel 
                            visible={true}
                            width={240}
                            placeholder={this.props.t('search')}
                        />

                        <FilterRow visible={true} />

                        <HeaderFilter visible={true} />

                        <Column 
                            dataField="User_Id" 
                            caption={this.props.t('profileNo')} 
                            alignment={columnAlign}
                        >
                        </Column>

                        <Column 
                            dataField={UserNameKey} 
                            caption={this.props.t('employeeName')} 
                            alignment={columnAlign}
                        >
                        </Column>

                        <Column 
                            dataField={DepartmentJobName} 
                            caption={this.props.t('departmentJob')} 
                            alignment={columnAlign}
                        >
                        </Column>

                        <Column 
                            dataField="User_Mail" 
                            caption={this.props.t('email')} 
                            alignment={columnAlign}
                        >
                        </Column>
                    </DataGrid>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentNamesOfEmployeesUnderMyManagementList))