import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Selection
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Switch from '@material-ui/core/Switch';

import { getDepartmentJobPermissionsList } from '../../../apis/departments/getDepartmentJobPermissionsList'
import { addDepartmentJobPermission } from '../../../apis/departments/addDepartmentJobPermission'
import { deleteDepartmentJobPermission } from '../../../apis/departments/deleteDepartmentJobPermission'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'


class DepartmentJobPermissionsList extends Component {

    state = {
		data: []
	};


    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Selected}
			name={`jobPermission_${data.Department_Job_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					status = data.Selected,
                    departmentJobId = this.props.departmentJobId,
                    departmentJobPermissionId = data.Department_Job_Permission_Id,
                    departmentJobPermissionTypeId = data.Department_Job_Permission_Type_Id,
                    response

                    this.props.setLoading(true, 'dark')

                    if (status) {
                        response = await deleteDepartmentJobPermission(at, departmentJobId, departmentJobPermissionId )
                    } else {
                        response = await addDepartmentJobPermission(at, departmentJobId, departmentJobPermissionTypeId )
                    }
                    
					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }

    render() {

        let columnAlign = 'left',
        PermissionCategoryName = 'Department_Job_Permission_Category_Name_EN',
        PermissionTypeName = 'Department_Job_Permission_Type_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            PermissionCategoryName = 'Department_Job_Permission_Category_Name_AR'
            PermissionTypeName = 'Department_Job_Permission_Type_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Department_Job_Permission_Id',
            load: () => {
                return getDepartmentJobPermissionsList(at, this.props.departmentJobId)
            },
            onLoaded: (res) => {
                if (res?.length == 0) {
                    this.props.showTab(false)
                } else {
                    this.props.showTab(true)
                }
            }
        })
        
        return(
            <>

            <div className="container-fluid pb-3">
                
                <DataGrid
                    ref={this.dataGridRef}
                    className={this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en'}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
                    columnWidth={'auto'}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <ColumnChooser enabled={true}  allowSearch={true}/>

                    <SearchPanel 
                        visible={true}
                        width={240}
                        placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />
                    
                    <Column
                        width="100px"
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                    />

                    <Column dataField={PermissionTypeName} caption={this.props.t('permission')} alignment={columnAlign}/>
                   
                    <Column dataField={PermissionCategoryName} caption={this.props.t('permissionClassification')} alignment={columnAlign}/>
                </DataGrid>

            </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentJobPermissionsList))