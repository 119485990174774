import apiConfig from '../apiConfig';

export const addDepartmentJobPermission = async (at, departmentJobId, departmentJobPermissionTypeId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_JOB_PERMISSION', 
		{
			Department_Job_Id: departmentJobId,
            Department_Job_Permission_Type_Id: departmentJobPermissionTypeId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
