import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Link } from "@reach/router";
import moment from "moment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import CustomStore from "devextreme/data/custom_store";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	StateStoring,
	Pager,
	Paging,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { ButtonInfo } from "../../UI/Buttons";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";
import FilesUpload from "../../UI/FileUpload/UploadFile";

class CompanyLicensesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
		this.devRef = React.createRef(null);
		this.searchState = React.createRef();
	}

	state = {
		gridState: [],
		gridKey: "",
		filterResults: 0,
		showGrid: false,
		searchResults: 0,
		saveGrid: false,
		pageSize: null,
		focusRow: -1,
		licensesList: [],
		showAddBtn: false,
	};

	getCompanyLicensesList = async () => {
		let auth = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/COMPANIES/LICENSES/Get_Company_Licenses_List",
			{
				Company_Id: this.props.companyId,
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data) {
			console.log(response);

			this.setState({
				licensesList: response?.data?.Data?.Licenses_List_Data,
				showAddBtn:
					response?.data?.Data?.Btn_Add_New == 0 ? false : true,
			});
		}
	};

	saveState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Companies_Licenses_List",
				System_Grid_Json: this.searchState.current,
				Lang: this.props.i18n.language,
			};

		await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		this.props.triggerNotification(true, {
			Status: true,
		});
	};

	getState = async (state) => {
		if (state) {
			state.searchText = "";
			state.columns.map((s) => (s.filterValue = undefined));
			this.searchState.current = JSON.stringify(state);
		}
	};

	loadState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Companies_Licenses_List",
				Lang: this.props.i18n.language,
			};

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		if (response.data) {
			this.setState({
				gridState: JSON.parse(response?.data?.Data),
				showGrid: true,
				pageSize: JSON.parse(response?.data?.Data)?.pageSize,
			});
		}
	};

	onToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("saveGridSettings"),
					height: "36px",
					icon: "save",
					onClick: () => this.saveState(),
					hint: this.props.t("saveGridSettings"),
				},
			},
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("clearSearch"),
					height: "36px",
					icon: "clearformat",
					onClick: () => this.dataGrid?.instance?.clearFilter(),
					hint: this.props.t("clearSearch"),
				},
			}
		);
	}

	handleOnContentReady = (e) => {
		if (this.props.i18n.language === "ar") {
			const scrollable = e.component.getScrollable();
			const maxScrollLeft = scrollable?.scrollWidth();

			scrollable?.scrollTo({
				x: maxScrollLeft,
			});
		}
	};

	componentDidMount() {
		this.getCompanyLicensesList();
		this.setState({ showGrid: false, saveGrid: false });
		this.dataGrid?.instance?.clearFilter();
		this.loadState();
	}

	render() {
		const jsonDataSource = new CustomStore({
			key: "License_Id",
			load: () => {
				let data = this.state.licensesList;
				return data;
			},
		});

		return (
			<>
				{this.state.showAddBtn && (
					<div className="d-flex justify-content-start mb-3">
						<ButtonPrimary
							variant={"contained"}
							component={Link}
							to={`/manage-companies/add-company-license/${this.props.companyId}`}
							size="large"
						>
							{this.props.t("addLicense")}
						</ButtonPrimary>
					</div>
				)}

				<DataGrid
					className={`${[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox ",
						this.state.showGrid ? "d-block" : "d-none",
					].join(" ")} `}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					zshowColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					wordWrapEnabled={true}
					onToolbarPreparing={this.onToolbarPreparing}
					bounceEnabled={false}
					onOptionChanged={(e) => {
						if (e?.fullName == "paging.pageSize") {
							this.setState({ pageSize: e.value });
						}

						if (e.fullName.split(".").pop() == "filterValue") {
							this.dataGrid.instance.refresh();
						}
					}}
					onContentReady={this.handleOnContentReady}
					remoteOperations={false}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					ref={(ref) => (this.dataGrid = ref)}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<Pager
						visible="true"
						allowedPageSizes={[5, 10, 20, 50, 100]}
						displayMode="full"
						showPageSizeSelector="true"
						showInfo="true"
						infoText=""
						showNavigationButtons="true"
					/>

					<Paging
						enabled={true}
						defaultPageSize={this.state.pageSize}
						pageSize={this.state.pageSize}
					/>

					<StateStoring
						enabled={true}
						type="custom"
						// customLoad={this.loadState}
						customSave={this.getState}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} applyFilter={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />

					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					{this.state.gridState?.columns
						?.filter((d) => d.name !== "buttons")
						.map((d, i) => {
							if (d.dataType == "date") {
								return (
									<Column
										dataField={d.dataField}
										caption={d.name}
										alignment={
											this.props.i18n.language === "ar"
												? "right"
												: "left"
										}
										visible={d.visible}
										width={d.width ? d.width : "auto"}
										dataType={d.dataType}
										format={
											d.dataType == "date"
												? "yyyy-MM-dd"
												: ""
										}
										sortIndex={0}
										sortOrder="desc"
									></Column>
								);
							} else {
								return (
									<Column
										dataField={d.dataField}
										caption={d.name}
										alignment={
											this.props.i18n.language === "ar"
												? "right"
												: "left"
										}
										visible={d.visible}
										width={d.width ? d.width : "auto"}
										dataType={d.dataType}
										defaultSortOrder="asc"
									></Column>
								);
							}
						})}

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						width="95"
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										component={Link}
										to={`/manage-companies/company-info/license-info/${record.data.Company_Id}/${record.data.License_Id}`}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let gridName = this.props.gridName;
		let name = gridName;
		let sheetTitle = name.charAt(0).toUpperCase() + name.slice(1);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		})
			.then(() => {
				// header
				const headerRow = worksheet.getRow(1);
				headerRow.height = 40;
				let centerCell = Math.round(worksheet.actualColumnCount / 2);
				headerRow.getCell(centerCell).value = this.props.gridName;
				headerRow.getCell(centerCell).font = {
					name: "Segoe UI Light",
					size: 22,
					bold: true,
				};
				headerRow.getCell(centerCell).alignment = {
					horizontal: "center",
				};
			})
			.then(function () {
				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(
						new Blob([buffer], {
							type: "application/octet-stream",
						}),
						`${sheetTitle}.xlsx`
					);
				});
			});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(CompanyLicensesList));
