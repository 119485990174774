import React, { Component } from "react";
import { connect } from "react-redux";
import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
	triggerDialogForm,
} from "./../../../actions";
import { translationHook } from "./../../translationHook";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "./../../UI/Buttons";
import { Link } from "@reach/router";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";

class ModificationsDetailsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	customizeCellCheckbox = ({ data }, key) => {
		if (data.Data_Type === 1) {
			return <span>{data[key]}</span>;
		} else {
			if (!data[key] || data[key] === "") {
				return;
			}

			return (
				<div>
					<GetAppIcon
						style={{
							cursor: "pointer",
							marginInline: "8px",
						}}
						onClick={async () => {
							let params = {
								at: this.props.currentUser.Data.access_token,
								File_Code: data[key],
							};
							const downloadResult = await downloadAttachment(
								params
							)
								.then((res) => {
									if (res.data.Status) {
										const url = window.URL.createObjectURL(
											new Blob([downloadResult.data], {
												type: "application/pdf",
											})
										);
										const link =
											document.createElement("a");
										link.href = url;
										link.setAttribute("target", "_blank");
										link.click();
									} else {
										throw res;
									}
									return res;
								})
								.catch((err) => {
									this.props.setLoading(false);
									this.props.triggerNotification(
										true,
										err.response
											? err.response.data
											: err.data
									);

									return err;
								});
						}}
					/>

					<ButtonInfo
						style={{
							marginInline: "8px",
						}}
						variant="circleIcon"
						startIcon={<VisibilityIcon />}
						onClick={async () => {
							let params = {
								at: this.props.currentUser.Data.access_token,
								File_Code: data[key],
							};
							const downloadResult = await downloadAttachment(
								params
							)
								.then((res) => {
									if (res.data.Status) {
										let contentType =
												downloadResult.headers[
													"content-type"
												],
											typesArray = [
												"application/pdf",
												"image/bmp",
												"image/gif",
												"image/vnd.microsoft.icon",
												"image/jpeg",
												"image/png",
												"image/svg+xml",
												"image/tiff",
												"image/webp",
											],
											url = window.URL.createObjectURL(
												new Blob(
													[downloadResult.data],
													{
														type: contentType,
													}
												)
											),
											link = document.createElement("a");
										let File_Name;
										if (
											downloadResult?.data?.Data
												?.File_Bytes
										) {
											url = window.URL.createObjectURL(
												new Blob(
													[
														downloadResult.data.Data
															.File_Bytes,
													],
													{
														type: contentType,
													}
												)
											);
											File_Name =
												downloadResult.data.Data
													.File_Name;
										}
										link.href = url;
										typesArray.includes(contentType)
											? link.setAttribute(
													"target",
													"_blank"
											  )
											: link.setAttribute(
													"download",
													File_Name
											  );
										document.body.appendChild(link);
										link.click();
									} else {
										throw res;
									}
									return res;
								})
								.catch((err) => {
									this.props.setLoading(false);
									this.props.triggerNotification(
										true,
										err.response
											? err.response.data
											: err.data
									);

									return err;
								});
						}}
					></ButtonInfo>
				</div>
			);
		}
	};

	render() {
		let at = this.props.currentUser.Data.access_token;

		const jsonDataSource = new CustomStore({
			key: "Detail_Id",
			load: () => {
				return this.props.modificationsList ?? [];
			},
		});

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Detail_Name_AR"
								: "Detail_Name_EN"
						}
						caption={this.props.t("details")}
						alignment={"center"}
					></Column>

					<Column
						dataField="Detail_Value_Old"
						caption={this.props.t("oldValue")}
						allowFiltering={false}
						alignment={"center"}
						cellRender={(record) => {
							return this.customizeCellCheckbox(
								record,
								"Detail_Value_Old"
							);
						}}
					></Column>

					<Column
						dataField="Detail_Value_New"
						caption={this.props.t("newValue")}
						allowFiltering={false}
						alignment={"center"}
						cellRender={(record) => {
							return this.customizeCellCheckbox(
								record,
								"Detail_Value_New"
							);
						}}
					></Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("licensesTrademarkNamesList");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
	triggerDialogForm,
})(translationHook(ModificationsDetailsList));
