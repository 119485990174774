import apiConfig from '../apiConfig';

export const getDepartmentManagerJobsList = async (at, departmentJobId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/GET_DEPARTMENT_MANAGER_JOBS_LIST', 
		{
			Department_Job_Id: departmentJobId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Selected === 1) {
				response.data.Data[i].Selected = true;
			} else {
				response.data.Data[i].Selected = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
