import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import { Form } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import style from './DepartmentJob.module.scss'

import DepartmentJobSectionsList from "./DepartmentJobSectionsList";
import DepartmentJobGroupWorkersNameList from "./DepartmentJobGroupWorkersNameList";
import DepartmentNamesOfEmployeesUnderMyManagementList from "./DepartmentNamesOfEmployeesUnderMyManagementList";
import DepartmentWorkingGroupsUnderMyManagementList from "./DepartmentWorkingGroupsUnderMyManagementList";
import DepartmentJobPermissionsList from "./DepartmentJobPermissionsList";

class DepartmentJobFrom extends Component {
	state = {
		showForm: false,
		showSectionsList: false,
		jobTypes: [],
		showNamesOfEmployeesUnderMyManagementList: false,
		showJobPermissionsList: false,
		showWorkingGroupsUnderMyManagementList: false
	};

	getJobTypes = async () => {
		let jobTypes = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_DEPARTMENT_JOBS_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				jobTypes.push({ value: item.Record_Id, label });
			});

			this.setState({ jobTypes });
		}
	};

	renderJobSectionsList = (...params) => {
		return <DepartmentJobSectionsList departmentJobId={params[0]} />;
	};

	handleJobSections = (data) => {
		let departmentJobId = data.Department_Job_Id,
			jobName =
				this.props.i18n.language === "ar"
					? data.Department_Job_Name_AR
					: data.Department_Job_Name_EN,
			dialogTitle = `${
				this.props.viewMood
					? this.props.t("jobSections")
					: this.props.t("selectJobSections")
			} - ${jobName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderJobSectionsList,
			params: [departmentJobId],
		});
	};

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_JOB"
				: "/API/LOOKUPS/DEPARTMENTJOBS/UPD_DEPARTMENT_JOB",
			bodyData = {
				Department_Job_Name_AR: values.jobNameAR,
				Department_Job_Name_EN: values.jobNameEN,
				Department_Job_Type_Id: values.jobType,
				Department_Job_Signature_Text: values.jobSignature,
				Active_Status_Id: values.jobStatus ? 1 : 0,
			};

		if (this.props.editMood) {
			bodyData.Department_Job_Id = this.props.departmentJobId;
		}

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		if (response.data.Status) {
			this.setState({ showForm: false });
			if (!this.props.editMood) {
				form.restart();
				this.handleJobSections(response.data.Data);
			} else {
				this.setState({ showSectionsList: true });
			}
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

	componentDidMount() {
		this.getJobTypes();
	}

	handleShowNamesOfEmployeesUnderMyManagementList = (state) => {
		this.setState({showNamesOfEmployeesUnderMyManagementList: state})
	}

	handleShowjobPermissionsList = (state) => {
		this.setState({showJobPermissionsList: state})
	}

	handleShowWorkingGroupsUnderMyManagementList = (state) => {
		this.setState({showWorkingGroupsUnderMyManagementList: state})
	}

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};

			if (!values.jobNameAR) {
				errors.jobNameAR = `${this.props.t("error.field_required")}`;
			}

			if (!values.jobNameEN) {
				errors.jobNameEN = `${this.props.t("error.field_required")}`;
			}

			if (!values.jobType) {
				errors.jobType = `${this.props.t("error.field_required")}`;
			}

			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("departmentJobs")}</h3>
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addJob")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}

				<Form
					onSubmit={this.onSectionSubmit}
					initialValues={
						initValues
							? initValues
							: {
									jobStatus: true,
									jobNameAR: "",
									jobNameEN: "",
									jobType: "",
									jobNo: "",
									jobSignature: ""
							  }
					}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="sliderform"
							className={`row ${
								!this.state.showForm &&
								!this.props.editMood &&
								!this.props.viewMood
									? "d-none"
									: ""
							}`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={6}>
								<TextField
									disabled
									label={this.props.t("jobNo")}
									className="mb-2"
									name="jobNo"
								/>
							</Col>

							<Col
								xs={12}
								md={6}
								className={`${
									this.props.viewMood ? "read-only" : ""
								}`}
							>
								<Switches
									disabled={this.props.viewMood}
									color="primary"
									name="jobStatus"
									data={{
										label: `${this.props.t("jobStatus")}`,
										value: `${this.props.t("jobStatus")}`,
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("jobNameAR")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="jobNameAR"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("jobNameEN")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="jobNameEN"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<Select
									disabled={this.props.viewMood}
									label={this.props.t("jobType")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									required={true}
									name="jobType"
									data={this.state.jobTypes}
									showError={() => {
										let fieldStatus =
											form.getFieldState("jobType");

										if (fieldStatus) {
											return (
												fieldStatus.submitFailed &&
												fieldStatus.invalid
											);
										}
									}}
								></Select>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("jobSignature")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="jobSignature"
									required={false}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>

				{(this.props.editMood || this.props.viewMood) && (
					<div className={style.depJobTabs}>
						<Tab.Container id="department-jobs-tabs" defaultActiveKey={0}>
							<Nav variant="pills" className={style.tabsPills}>
								<Nav.Item className={style.tabsItems}>
									<Nav.Link eventKey="0" className={style.tabsLink}>
										{this.props.t('jobSections')}
									</Nav.Link>
								</Nav.Item>
								<Nav.Item className={style.tabsItems}>
									<Nav.Link eventKey="1" className={style.tabsLink}>
										{this.props.t('jobGroupWorkersName')}
									</Nav.Link>
								</Nav.Item>
								{this.state.showWorkingGroupsUnderMyManagementList &&
									<Nav.Item className={style.tabsItems}>
										<Nav.Link eventKey="2" className={style.tabsLink}>
											{this.props.t('workingGroupsUnderMyManagement')}
										</Nav.Link>
									</Nav.Item>
								}
								{this.state.showNamesOfEmployeesUnderMyManagementList &&
									<Nav.Item className={style.tabsItems}>
										<Nav.Link eventKey="3" className={style.tabsLink}>
											{this.props.t('namesOfEmployeesUnderMyManagement')}
										</Nav.Link>
									</Nav.Item>
								}
								{this.state.showJobPermissionsList &&
									<Nav.Item className={style.tabsItems}>
										<Nav.Link eventKey="4" className={style.tabsLink}>
											{this.props.t('jobPermissions')}
										</Nav.Link>
									</Nav.Item>
								}
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey={0}>
									<DepartmentJobSectionsList departmentJobId={this.props.departmentJobId} />
								</Tab.Pane>
								<Tab.Pane eventKey={1}>
									<DepartmentJobGroupWorkersNameList departmentJobId={this.props.departmentJobId} />
								</Tab.Pane>
								<Tab.Pane eventKey={2}>
									<DepartmentWorkingGroupsUnderMyManagementList 
										departmentJobId={this.props.departmentJobId} 
										showTab={this.handleShowWorkingGroupsUnderMyManagementList}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey={3}>
									<DepartmentNamesOfEmployeesUnderMyManagementList 
										departmentJobId={this.props.departmentJobId} 
										showTab={this.handleShowNamesOfEmployeesUnderMyManagementList}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey={4}>
									<DepartmentJobPermissionsList 
										departmentJobId={this.props.departmentJobId} 
										showTab={this.handleShowjobPermissionsList}
									/>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				)}
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(DepartmentJobFrom));
