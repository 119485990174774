import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import DateBox from "devextreme-react/date-box";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import moment from "moment";
import { Link, navigate } from "@reach/router";
import { ButtonSecondary, ButtonPrimary, ButtonDanger } from "../../UI/Buttons";
import apiConfig from "../../../apis/apiConfig";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import {
	getCompanyDetails,
	getCompanyLicenseDetails,
} from "../../../apis/licenses/LicensesList";

import CompanyLicensesActivitiesList from "./CompanyLicensesActivitiesList";

class CompanyLicenseForm extends Component {
	state = {
		initValues: {},
		Company_Data_Validation: [],
		License_Data_Validation: {},
		Company_Details: {},
		License_Details: {},
		Company_System_Validation: {},
		License_System_Validation: {},
		Btns: [],
		companyStatusList: [],
		licenseStatusList: [],
		ownershipStructureList: [],
		legalFormsList: [],
		licenseOfferList: [],
		licenseYearsList: [],
		clientsList: [],
		selectedLegalForm: null,
		selectedOfferId: null,
		Electronic_Signature_File: [],
		Electronic_Signature_File_Code: null,
		dataLoaded: false,
	};

	selectFiles = (event, field) => {
		let filesData = [];

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				filesData.push(element);
			}
		}

		this.setState({ Electronic_Signature_File: filesData });
	};

	getLicenseStatusList = async () => {
		let statusArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_STATUS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				statusArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseStatusList: statusArr });
		}
	};

	getOwnershipStructureList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_OWNERSHIP_STRUCTURES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ ownershipStructureList: arr });
		}
	};

	getLegalFormsList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LEGALS_FORMS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Legal_Form_Name_AR
						: item.License_Legal_Form_Name_EN;

				arr.push({
					value: item.License_Legal_Form_Id,
					label,
				});
			});

			this.setState({ legalFormsList: arr });
		}
	};

	getLicenseOfferList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_OFFERS",
			{ License_Legal_Form_Id: this.state.selectedLegalForm }
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseOfferList: arr });
		}
	};

	getLicenseYearsList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_OFFERS_REGISTRATION",
			{ License_Offer_Id: this.state.selectedOfferId }
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseYearsList: arr });
		}
	};

	getClientsList = async () => {
		let arr = [],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/COMPANIES/Get_Companies_Main_Clients",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Mail_Client_Profile_Name_AR
						: item.Mail_Client_Profile_Name_EN;

				arr.push({
					value: item.Mail_Client_Profile_Id,
					label,
				});
			});

			this.setState({ clientsList: arr });
		}
	};

	getFacilityTypesList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_FACILITIES_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ facilityTypes: arr });
		}
	};

	deleteLicenseDetails = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/LICENSES/Del_License";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{
					Company_Id: this.props.companyId,
					License_Id: this.props.licenseId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						License_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	issueLegalFiles = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/Print_Company_Legal_Files";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Company_Id: this.props.companyId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						License_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	updateLicenseDetails = async (values, form, print) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/LICENSES/Upd_License";

		let bodyData = {
			...values,
			...{
				Print_Legal_Files: 0,
			},
		};

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					this.setState({
						License_Data_Validation: !res.data?.Data
							?.License_Data_Validation?.Validation_Result
							? res.data?.Data?.License_Data_Validation
									?.Validation_Results_List
							: res.data?.Data?.License_Data_Validation,
						License_Details: res.data?.Data?.License_Details,
						License_System_Validation:
							res.data?.Data?.License_System_Validation,
						activitiesList:
							res.data?.Data?.License_Details?.Activities_List,
						Btns: {
							Btn_Add_Activity: res.data?.Data?.Btn_Add_Activity,
							Btn_Delete: res.data?.Data?.Btn_Delete,
							Btn_Print: res.data?.Data?.Btn_Print,
							Btn_Save: res.data?.Data?.Btn_Save,
							Btn_Save_Print: res.data?.Data?.Btn_Save_Print,
						},
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						License_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});

		if (print) {
			this.issueLegalFiles();
		}
	};

	addLicense = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/COMPANIES/LICENSES/Add_License";

		let bodyData = {
			...values,
			Company_Id: this.props.companyId,
		};

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);

					this.setState({
						License_Data_Validation: {},
					});

					navigate(
						`/manage-companies/company-info/license-info/${res.data.Data.License_Details.Company_Id}/${res.data.Data.License_Details.License_Id}`
					);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						License_Data_Validation:
							err?.data?.Data?.Validation_Results_List,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	componentDidMount() {
		let licenseId = this.props.licenseId,
			auth = this.props.currentUser.Data.access_token;

		Promise.all([this.getLicenseStatusList()]).then(() => {
			this.setState({ dataLoaded: true });

			if (licenseId) {
				(async () => {
					const licenseDetails = await getCompanyLicenseDetails(
						auth,
						licenseId
					);

					this.setState({
						License_Details: licenseDetails?.License_Details,
						License_System_Validation:
							licenseDetails?.License_System_Validation,
						activitiesList:
							licenseDetails?.License_Details?.Activities_List,
						Btns: {
							Btn_Add_Activity: licenseDetails?.Btn_Add_Activity,
							Btn_Delete: licenseDetails?.Btn_Delete,
							Btn_Print: licenseDetails?.Btn_Print,
							Btn_Save: licenseDetails?.Btn_Save,
							Btn_Save_Print: licenseDetails?.Btn_Save_Print,
						},
					});
				})();
			}
		});
	}

	handleAddActivityCB = (licenseDetails) => {
		this.setState({
			License_Details: licenseDetails?.License_Details,
			License_System_Validation:
				licenseDetails?.License_System_Validation,
			activitiesList: licenseDetails?.License_Details?.Activities_List,

			Btns: {
				Btn_Add_Activity: licenseDetails?.Btn_Add_Activity,
				Btn_Delete: licenseDetails?.Btn_Delete,
				Btn_Print: licenseDetails?.Btn_Print,
				Btn_Save: licenseDetails?.Btn_Save,
				Btn_Save_Print: licenseDetails?.Btn_Save_Print,
			},
		});
	};

	onSubmit = async (values) => {};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		let { License_System_Validation, License_Details } = this.state;

		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					<Form
						onSubmit={this.onSubmit}
						initialValues={License_Details}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
							handleReset,
						}) => (
							<form
								onSubmit={handleSubmit}
								onReset={handleReset}
								style={{ overflowX: "hidden" }}
								className="py-4 px-3"
								noValidate
							>
								<Row>
									{License_Details && (
										<Col md={12}>
											<div className="d-flex justify-content-between align-items-center mb-4">
												<h3>
													{this.props.t(
														"licenseDetailsScreen"
													)}
												</h3>

												<ButtonSecondary
													variant="outlined"
													endIcon={<ExitToAppIcon />}
													component={Link}
													to={`/manage-companies/company-info/${this.props.companyId}`}
													size="large"
													state={{ tap: 1 }}
												>
													{this.props.t(
														"backToLicensesList"
													)}
												</ButtonSecondary>
											</div>

											<Row>
												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"License_Id"
														)}
														className="read-only mb-3"
														name="License_Id"
													/>
												</Col>
												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Company_No"
														)}
														className="read-only mb-3"
														name="Company_No"
													/>
												</Col>
												<Col md={3}>
													<TextField
														label={this.props.t(
															"License_No"
														)}
														className="read-only mb-3"
														name="License_No"
													/>
												</Col>

												<Col md={3}>
													<Field name="License_Status_Id">
														{(fieldProps) => (
															<Select
																label={this.props.t(
																	"licenseStatus"
																)}
																className={`${
																	this.props
																		.viewMood
																		? "read-only"
																		: ""
																}`}
																data={
																	this.state
																		.licenseStatusList
																}
																name="License_Status_Id"
																onChange={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.target
																			.value
																	);
																}}
															></Select>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Activities_Count"
														)}
														className="read-only mb-3"
														name="Activities_Count"
													/>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Activities_Type"
														)}
														className="read-only mb-3"
														name="Activities_Type"
													/>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"Activities_Categories"
														)}
														className="read-only mb-3"
														name={
															this.props.i18n
																.language ===
															"ar"
																? "Activities_Categories_AR"
																: "Activities_Categories_EN"
														}
													/>
												</Col>

												<Col md={3}>
													<TextField
														disabled
														label={this.props.t(
															"License_Segment_Name"
														)}
														className="read-only mb-3"
														name={
															this.props.i18n
																.language ===
															"ar"
																? "License_Segment_Name_AR"
																: "License_Segment_Name_EN"
														}
													/>
												</Col>

												<Col md={6}>
													<TextField
														label={this.props.t(
															"License_Remarks_AR"
														)}
														className="read-only mb-3 mt-3"
														name="License_Remarks_AR"
														rows={8}
														multiline
													/>
												</Col>
												<Col md={6}>
													<TextField
														label={this.props.t(
															"License_Remarks_EN"
														)}
														className="read-only mb-3 mt-3"
														name="License_Remarks_EN"
														rows={8}
														multiline
													/>
												</Col>
											</Row>

											<Row>
												{!License_System_Validation?.Is_Valid &&
													License_System_Validation
														?.Validation_Errors_List
														?.length > 0 && (
														<>
															<Col xs={12}>
																<label
																	style={{
																		fontSize:
																			"1.2rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"licenseDataErrors"
																	)}
																</label>
															</Col>
															<Col
																xs={12}
																style={{
																	border: "4px solid red",
																	borderRadius:
																		"4px",
																	paddingBlock:
																		"18px",
																}}
															>
																<Row>
																	{License_System_Validation?.Validation_Errors_List?.map(
																		(
																			error
																		) => {
																			return (
																				<Col
																					md={
																						3
																					}
																				>
																					<h5>
																						{this
																							.props
																							.i18n
																							.language ===
																						"ar"
																							? error.Error_Source_AR
																							: error.Error_Source_EN}
																					</h5>

																					<p>
																						{this
																							.props
																							.i18n
																							.language ===
																						"ar"
																							? error.Error_Message_AR
																							: error.Error_Message_EN}
																					</p>
																				</Col>
																			);
																		}
																	)}
																</Row>
															</Col>
														</>
													)}
											</Row>
										</Col>
									)}
								</Row>

								<div className="d-flex justify-content-center align-itmes-center my-5 mt-4">
									{this.props.isAddForm && (
										<ButtonPrimary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.addLicense(values, form);
											}}
										>
											{this.props.t("addLicense")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save && (
										<ButtonPrimary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateLicenseDetails(
													values,
													form,
													false
												);
											}}
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateLicenseDetails(
													values,
													form,
													true
												);
											}}
										>
											{this.props.t("saveAndIssue")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.issueLegalFiles();
											}}
										>
											{this.props.t("issueLegalFiles")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Delete && (
										<ButtonDanger
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.deleteLicenseDetails();
											}}
										>
											{this.props.t("deleteLicenseData")}
										</ButtonDanger>
									)}
								</div>

								{this.state.License_Data_Validation.length >
									0 && (
									<Row>
										<Col
											xs={12}
											style={{
												border: "4px solid red",
												borderRadius: "4px",
												paddingBlock: "18px",
												color: "red",
											}}
										>
											<Row>
												{this.state.License_Data_Validation?.map(
													(error) => {
														if (!error.Result) {
															return (
																<Col md={3}>
																	<h5>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Title_Name_AR
																			: error.Title_Name_EN}
																	</h5>

																	<p>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Error_AR
																			: error.Error_EN}
																	</p>
																</Col>
															);
														}
													}
												)}
											</Row>
										</Col>
									</Row>
								)}

								{this.props.licenseId && (
									<CompanyLicensesActivitiesList
										handleAddActivityCB={
											this.handleAddActivityCB
										}
										activitiesList={
											this.state.activitiesList
										}
										showAddBtn={
											this.state.Btns.Btn_Add_Activity
										}
										licenseId={this.props.licenseId}
									/>
								)}
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(CompanyLicenseForm));
